import AttachmentIcon from '@mui/icons-material/Attachment';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { default as MuiList } from '@mui/material/List';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  IWorkOrderAttachment,
  useError,
  useGetWorkOrderAttachments,
  useGetWorkOrderAttachmentsCheck,
} from '../../hooks';
import { Doclink } from '../../typescript/types';
import { downloadHelper } from '../../utils';
import { Accordion, AlertBox, Button } from '../UI';

interface IProps {
  workorderid: string;
  docLinks: Doclink[];
  orgid: string;
  companyId: string;
}

export const FileList = ({ docLinks, workorderid, orgid, companyId }: IProps) => {
  const [setError] = useError();
  const [workOrderAttachment, setWorkOrderAttachment] = useState<IWorkOrderAttachment>(
    {} as IWorkOrderAttachment,
  );

  // Fetch attachment when workOrderAttachment updates
  const { data: attachment, isLoading, error } = useGetWorkOrderAttachments(workOrderAttachment);
  const { data: attachmentCheck, error: attachmentCheckError } = useGetWorkOrderAttachmentsCheck(
    docLinks,
    companyId,
  );

  const handleClick = (
    workorderid: string,
    doclinksid: string,
    urlname: string,
    orgid: string,
    companyId: string,
  ) => {
    setWorkOrderAttachment({ workorderid, doclinksid, urlname, orgid, companyId });
  };

  useEffect(() => {
    if (attachment?.data && Object.keys(workOrderAttachment).length > 0) {
      downloadHelper(attachment.data, workOrderAttachment.urlname);
      setWorkOrderAttachment({} as IWorkOrderAttachment);
    }
  }, [workOrderAttachment, attachment]);

  useEffect(() => {
    if (attachmentCheckError) {
      setError(attachmentCheckError);
    }

    if (error) {
      setError(error.message);
    }
  }, [error, attachmentCheckError, setError]);

  const summary = (
    <>
      <Title>
        <AttachmentIcon />
        <p>Attachments</p>
      </Title>
      <Circle>{docLinks.length ?? '0'}</Circle>
    </>
  );

  const filesHasExpired = attachmentCheck
    ? Math.min(...Object.values(attachmentCheck ?? {}).map((x) => x.expires)) < 60
    : false;

  const details = (
    <>
      {filesHasExpired && (
        <AlertBox severity="warning">
          The file links have expired due to inactivity, please reload the page {'  '}
          <Button onClick={() => window.location.reload()} variant="secondary" size="small">
            Reload
          </Button>
        </AlertBox>
      )}
      <List>
        {docLinks &&
          docLinks.map((doclink, index) => {
            if (filesHasExpired) {
              return (
                <ListItem key={index}>
                  <IconWrapper>
                    <InsertDriveFileOutlinedIcon fontSize="small" />
                  </IconWrapper>
                  <ExpiredText primary={doclink.urlname} />
                </ListItem>
              );
            }

            return (
              <ListItemButton
                key={index}
                onClick={() =>
                  handleClick(workorderid, doclink.doclinksid, doclink.urlname, orgid, companyId)
                }
                disabled={isLoading}>
                <IconWrapper>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                </IconWrapper>
                <ListText primary={doclink.urlname} />
              </ListItemButton>
            );
          })}
      </List>
    </>
  );

  return <Accordion summary={summary} details={details} />;
};

const List = styled(MuiList)`
  .MuiListSubheader-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    border-bottom: 1px solid var(--input-border);
    background-color: transparent;
    padding: 0;
  }
  .MuiListItemButton-root {
    background-color: #ffffff;
    gap: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 10%);

    .MuiTypography-root {
      font-size: 0.875rem;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;

  svg {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.background.cta};
  color: ${({ theme }) => theme.colors.text.onCta};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 10%);
  font-size: ${({ theme }) => theme.fonts.size.small};
`;

const IconWrapper = styled(Circle)`
  box-shadow: none;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 0.15rem;
  svg {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    position: relative;
    top: -1px;
  }
`;

const ListText = styled((props) => <ListItemText {...props} />)`
  font-size: 0.875rem;
`;

const ExpiredText = styled((props) => <ListItemText {...props} />)`
  font-size: 0.875rem;
  text-decoration: line-through;
`;
